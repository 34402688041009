.video-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: black;
}

.video-element {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.status-button {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    z-index: 1000;
    color: white;
}

.play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 15px 30px;
    font-size: 18px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    cursor: pointer;
    z-index: 1001;
}

.connected {
    background-color: green;
}

.disconnected {
    background-color: red;
}

.settings-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 12px; /* Adjusted font size for the icon */
    cursor: pointer;
    z-index: 1001;
    background-color: rgba(255, 255, 255, 0.5);
    color: black;
    margin: 10px; /* Added margin */
}

.settings-panel {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 20%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    z-index: 1002;
    box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.3);
}

.ping-display {
    margin-left: 20px;
    color: white;
    font-size: 18px;
    font-weight: bold;
}

.close-button {
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    margin-right: 20px; /* Added margin for the close button */
    margin-top: 10px;   /* Margin to the top */
}

.restart-button {
    margin-left: 20px; /* Spacing to the right of the Ping display */
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    color: black;
    font-weight: bold;
}
.restart-button:disabled {
    background-color: rgba(255, 255, 255, 0.3); /* Lighter color when disabled */
    cursor: not-allowed; /* Change cursor when button is disabled */
}

.status-button {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 2px 2px;
    border: none;
    border-radius: 50%;
    font-size: 8px;
    cursor: default;
    z-index: 1000;
    color: white;
    text-align: center;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Peer connection states */
.connected {
    background-color: green;
    box-shadow: 0 0 15px rgba(0, 255, 0, 0.5);
}

.connecting {
    background-color: yellow;
    color: black;
    box-shadow: 0 0 15px rgba(255, 255, 0, 0.5);
}

.disconnected {
    background-color: red;
    box-shadow: 0 0 15px rgba(255, 0, 0, 0.5);
}

.checking {
    background-color: #264385;
    box-shadow: 0 0 15px rgba(9, 12, 136, 0.5);
}
